<template>
  <div style="padding-bottom: 20px">
    <el-form :inline="true" :model="pendingPage" class="demo-form-inline">
      <el-form-item label="关键字">
        <el-input
          style="width: 250px"
          v-model="pendingPage.key"
          placeholder="订单编号、客户姓名、预约项目"
        ></el-input>
      </el-form-item>

      <el-form-item label="预约类型">
        <el-select
          v-model="pendingPage.appointment_type"
          placeholder="请选择预约类型"
        >
          <el-option label="专科医生" value="1"></el-option>
          <el-option label="健康体检" value="2"></el-option>
          <el-option label="预防疫苗" value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="预约时间">
        <el-date-picker
          v-model="pendingPage.appointment_time"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="datetime"
          placeholder="选择预约时间"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSearch">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="pendingList" style="width: 100%">
      <el-table-column prop="order_id" label="订单编号" width="200">
        <template slot-scope="scope">
          <el-link type="primary" @click="openDetail(scope.row.id)">{{
            scope.row.order_id
          }}</el-link>
        </template>
      </el-table-column>
      <el-table-column prop="pay_time" width="200" label="下单时间">
      </el-table-column>
      <el-table-column prop="patient_name" width="" label="客户姓名"></el-table-column>
      <el-table-column prop="mobile" width="150" label="联系电话"></el-table-column>
      <el-table-column prop="appointment_type" label="预约类型" width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.appointment_type == '1'">专科医生</span>
          <span v-if="scope.row.appointment_type == '2'">健康体检</span>
          <span v-if="scope.row.appointment_type == '3'">预防疫苗</span>
        </template>
      </el-table-column>
      <el-table-column prop="product_name" width="" label="预约项目">
      </el-table-column>
      <el-table-column prop="service_item_list" width="300" label="预约时段">
        <template slot-scope="scope">
          <div v-for="(item, index) in scope.row.service_item_list">
            <div v-if="item.appointment_start != null">{{item.appointment_start}} - {{item.appointment_end}}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="medical_price_hb" width="" label="金额">
      </el-table-column>
      <!-- <el-table-column
        prop="service_item_list"
        label="服务项确认状态"
        width="380"
      >
        <template slot-scope="scope">
          <div
            class="item-block-box"
            v-if="
              scope.row.service_item_list && scope.row.service_item_list.length
            "
          >
            <div
              v-for="(item, index) in scope.row.service_item_list"
              :key="index"
              class="item-block"
            >
              <div>{{ item.title }}</div>
              <div v-if="item.clinic_over_time != null">
                完成服务时间: {{ item.clinic_over_time }}
              </div>
              <div v-else-if="item.appointment_time != null">
                服务时间: {{ item.appointment_time }}
              </div>
              <div v-else>
                <span v-if="item.appointment_start != null"
                  >用户预约时间: {{ item.appointment_start }} -
                  {{ item.appointment_end }}</span
                >
                <span v-else>用户未提交预约时间</span>
              </div>
            </div>
          </div>
        </template>
      </el-table-column> -->

      <!-- <el-table-column prop="medical_price_hb" width="150" label="就诊费HK$">
      </el-table-column> -->

      <el-table-column label="操作" width="260">
        <template slot-scope="scope">
          <el-button type="primary" plain @click="openAction(scope.row.id)"
            >确认时间</el-button
          >
          <el-button
            type="primary"
            plain
            @click="openDetail(scope.row.id)"
          >预约详情</el-button>
        </template>

      </el-table-column>
    </el-table>
    <div
      style="
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-top: 20px;
      "
    >
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="pendingPage.limit"
        @current-change="changePendingPage"
        :current-page="pendingPage.start"
        :total="pendingPage.totalRow"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="设置或修改子订单服务时间"
      :visible="serviceDateVisible"
      width="400px"
      :destroy-on-close="true"
      :before-close="closeSetSubOrderServiceTime"
    >
      <el-form
        :model="serviceForm"
        label-width="80px"
        :rules="serviceFormRules"
        ref="serviceForm"
        status-icon
        class="login-form"
      >
        <el-form-item label="选择时间" prop="appointment_time">
          <el-date-picker
            v-model="serviceForm.appointment_time"
            type="datetime"
            format="yyyy-MM-dd HH:mm"
            value-format="yyyy-MM-dd HH:mm"
            placeholder="选择时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button
            @click="actionSetSubOrderServiceTime"
            :loading="$store.state.requestLoading"
            >确定
          </el-button>
          <el-button @click="resetSetSubOrderServiceTime">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      title="确认订单"
      :visible="passDateVisible"
      width="400px"
      :destroy-on-close="true"
      :before-close="closePassDateForm"
    >
      <el-form
        :model="passForm"
        label-width="80px"
        :rules="passFormRules"
        ref="passForm"
        status-icon
        class="login-form"
      >
        <el-form-item label="确认时间" prop="appointment_time">
          <el-time-select
            v-model="passForm.appointment_time"
            :picker-options="{
              start: passForm.start_time,
              step: '00:01',
              end: passForm.end_time,
            }"
            placeholder="确认时间"
          >
          </el-time-select>
        </el-form-item>
        <el-form-item>
          <el-button
            @click="actionPassDate"
            :loading="$store.state.requestLoading"
            >确定
          </el-button>
          <el-button @click="resetPassDateForm">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      title="设置服务时间"
      :visible="actionVisible"
      width="900px"
      :destroy-on-close="true"
      :before-close="() => (actionVisible = false)"
    >
      <el-table :data="detailList">
        <el-table-column
          prop="title"
          label="名称"
          width="200"
        ></el-table-column>
        <el-table-column
          prop="appointment_start"
          width=""
          label="预约时间-开始"
          width="150"
        ></el-table-column>
        <el-table-column
          prop="appointment_end"
          width=""
          label="预约时间-结束"
          width="150"
        ></el-table-column>
        <el-table-column
          prop="appointment_time"
          width=""
          label="具体服务时间"
          width="150"
        ></el-table-column>
<!--        <el-table-column label="状态" width="200">-->
<!--          <template slot-scope="scope">-->
<!--            <span v-if="scope.row.canBeModifiedTime == '1'">等待用户选择预约时间</span>-->
<!--            <span v-if="scope.row.canBeModifiedTime == '3'">中介待确认</span>-->
<!--          </template>-->
<!--        </el-table-column>-->
        <el-table-column
          prop="canBeModifiedTime"
          width=""
          label="操作"
          width="200"
        >
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.canBeModifiedTime == '1'"
              type="default"
              disabled
              >不可设置</el-button
            >
            <el-button
              v-if="scope.row.canBeModifiedTime == '2'"
              type="primary"
              plain
              @click="openChangeDate(scope.row, 2)"
              >设置服务时间
            </el-button>
            <el-button
              v-if="scope.row.canBeModifiedTime == '3'"
              type="primary"
              plain
              @click="openChangeDate(scope.row, 3)"
              >修改服务时间
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog
      title="订单详情"
      :visible="detailsVisible"
      width="600px"
      :destroy-on-close="true"
      :before-close="() => (detailsVisible = false)"
    >
      <div class="cu-order-dialog">
        <el-card class="box-card" style="margin-bottom: 20px">
          <div
            slot="header"
            style="text-align: center; font-weight: bold; font-size: 16px"
          >
            <span>客户详情</span>
          </div>
          <div>
            <div class="detail-list">
              <div class="detail-left">客户姓名:</div>
              <div class="detail-right">{{ orderDetailInfo.name }}</div>
            </div>
            <div class="detail-list">
              <div class="detail-left">证件类型:</div>
              <div class="detail-right">
                <span v-if="orderDetailInfo.card_type == '1'">中国身份证</span>
                <span v-if="orderDetailInfo.card_type == '2'">香港身份证</span>
                <span v-if="orderDetailInfo.card_type == '3'">其它</span>
              </div>
            </div>
            <div class="detail-list">
              <div class="detail-left">证件号码:</div>
              <div class="detail-right">{{ orderDetailInfo.card_num }}</div>
            </div>
            <div class="detail-list">
              <div class="detail-left">联系电话:</div>
              <div class="detail-right">{{ orderDetailInfo.mobile }}</div>
            </div>
            <div class="detail-list">
              <div class="detail-left">预约类型:</div>
              <div class="detail-right">
                <span v-if="orderDetailInfo.appointment_type == '1'"
                  >专科医生</span
                >
                <span v-if="orderDetailInfo.appointment_type == '2'"
                  >健康体检</span
                >
                <span v-if="orderDetailInfo.appointment_type == '3'"
                  >预防疫苗</span
                >
              </div>
            </div>
            <div class="detail-list">
              <div class="detail-left">预约项目:</div>
              <div class="detail-right">{{ orderDetailInfo.title }}</div>
            </div>
            <div class="detail-list">
              <div class="detail-left">订单创建时间:</div>
              <div class="detail-right">{{ orderDetailInfo.create_time }}</div>
            </div>
            <div class="detail-list">
              <div class="detail-left">就诊费优惠金额:</div>
              <div class="detail-right">
                HK${{ orderDetailInfo.coupon_price }}
              </div>
            </div>
            <div class="detail-list">
              <div class="detail-left">应收就诊费:</div>
              <div class="detail-right">
                HK${{ orderDetailInfo.medical_price_hb }}
              </div>
            </div>
            <div class="detail-list">
              <div class="detail-left">症状描述:</div>
              <div class="detail-right">
                {{ orderDetailInfo.content }}
              </div>
            </div>
            <div class="detail-list">
              <div class="detail-left">附件:</div>
              <div class="detail-right"><small-image-list v-bind:img_url.sync="orderDetailInfo.annex_urls" /></div>
            </div>
          </div>
        </el-card>
        <el-card
          class="box-card"
          style="margin-bottom: 20px"
          v-if="orderDetailInfo.appointment_type != 1"
        >
          <div
            slot="header"
            style="text-align: center; font-weight: bold; font-size: 16px"
          >
            <span>项目详情</span>
          </div>
          <div
            v-for="(item, index) in orderDetailInfo.projectList"
            :key="index"
            style="margin-bottom: 20px"
          >
            <div style="text-align: center">
              <span
                style="
                  background: #eeeeee;
                  padding: 4px 10px;
                  border-radius: 10px;
                "
                >{{ item.title }}</span
              >
            </div>
            <div v-for="(item1, index1) in item.item" :key="index1">
              <div style="color: #333333; margin-bottom: 5px">
                {{ item1.name }}
              </div>
              <div style="color: #666666; margin-bottom: 20px">
                {{ item1.content }}
              </div>
              <div
                style="margin-top: 20px"
                v-if="orderDetailInfo.appointment_type != 2"
              >
                <div
                  style="
                    display: flex;
                    border-bottom: 1px solid #eee;
                    border-top: 1px solid #eee;
                    line-height: 30px;
                  "
                >
                  <div style="flex: 1; text-align: center">安排</div>
                  <div style="flex: 1; text-align: center">时间</div>
                </div>
                <div
                  style="
                    display: flex;
                    border-bottom: 1px solid #eee;
                    line-height: 30px;
                    margin-bottom: 20px;
                  "
                  v-for="(item2, index2) in item1.implementation_plan"
                  :key="index2"
                >
                  <div style="flex: 1; text-align: center">
                    第{{ item2.num }}针
                  </div>
                  <div style="flex: 1; text-align: center">
                    {{ item2.maa_date }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-card>
        <el-card class="box-card">
          <div
            slot="header"
            style="text-align: center; font-weight: bold; font-size: 16px"
          >
            <span>服务记录</span>
          </div>
          <el-table :data="orderDetailInfo.serviceRecordList">
            <el-table-column prop="content" label="服务内容"></el-table-column>
            <el-table-column
              prop="create_time"
              width=""
              label="创建时间"
              width="200"
            ></el-table-column>
          </el-table>
        </el-card>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as Api from "./api";
import * as Common from "../../../common/common";

export default {
  name: "order",
  props: {
    activeIndex: [String],
    rowId: String,
  },
  watch: {
    activeIndex: {
      handler: function (e) {
        console.log(e)
        if (e == 1) {
          this.pendingOrderPage();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    let isThanCurrentDate = (rule, value, callback) => {
      let c = new Date().getTime();
      let s = new Date(value).getTime();

      if (s < c) {
        callback(new Error("请选择大于当前的时间"));
      } else {
        callback();
      }
    };
    return {
      pendingPage: {
        start: 1,
        limit: 20,
        pageSize: 0,
        totalRow: 0,
        key: "",
        type: "",
        appointment_time: "",
        state: undefined,
      },
      pendingList: [],
      serviceDateVisible: false,
      currentDate: "",
      serviceForm: {
        appointment_time: "",
        id: "",
      },
      serviceFormRules: {
        appointment_time: [
          { required: true, message: "请选择服务时间", trigger: "change" },
          { validator: isThanCurrentDate, trigger: "change" },
        ],
      },
      passDateVisible: false,
      passForm: {
        appointment_time: "",
        start_time: "",
        end_time: "",
        ny: "",
        id: "",
      },
      passFormRules: {
        appointment_time: [
          { required: true, message: "请选择", trigger: "change" },
        ],
      },
      orderId: "",
      orderDetail: {},
      actionVisible: false,
      detailList: [],
      detailsVisible: false,
      detailId: "",
      orderDetailInfo: {},
    };
  },
  mounted() {
    this.pendingOrderPage();
    console.log("rowId" + this.rowId);
    if (this.rowId != undefined && this.rowId != "") {
      this.openAction(this.rowId);
    }
  },
  methods: {
    /**
     * 打开订单详情
     * @author wheat
     * */
    openDetail(id) {
      this.detailId = id;
      this.detailsVisible = true;
      this.getOrderDetailInfo();
    },
    /**
     * 订单详情
     * @author wheat
     * */
    async getOrderDetailInfo() {
      const res = await Api.GetOrderDetailInfo({ id: this.detailId });
      if (res.target.appointment_type != 1) {
        res.target.projectList.forEach((project) => {
          project.item.forEach((item) => {
            try {
              item.implementation_plan = JSON.parse(item.implementation_plan);
            } catch (e) {
              item.implementation_plan = [];
            }
          });
        });
      }
      this.orderDetailInfo = res.target;
    },
    /**
     * 获取待服务订单列表
     * @author wheat
     * */
    async pendingOrderPage() {
      let res = await Api.PendingOrderPage(this.pendingPage);
      if (res.code == "0") {
        this.pendingPage.pageSize = res["page"]["pageSize"];
        this.pendingPage.totalRow = res["page"]["totalRow"];
        this.pendingList = res["page"]["list"];
      } else {
        this.$message.error(res.message);
      }
    },
    /**
     * 打开待服务订单确认窗口
     * @author wheat
     * */
    openPass(record) {
      this.passForm.start_time = record.appointment_start.substr(11);
      this.passForm.end_time = record.appointment_end.substr(11);
      this.passForm.ny = record.appointment_end.substr(0, 11);
      this.passForm.id = record.id;
      this.passDateVisible = true;
    },
    /**
     * 待服务订单确认
     * @author wheat
     * */
    async actionPassDate() {
      this.$refs["passForm"].validate(async (valid) => {
        if (valid) {
          let params = Object.assign({}, this.passForm);
          params["appointment_time"] =
            params["ny"] + params["appointment_time"];
          let res = await Api.Pass(params);
          if (res.code == "0") {
            this.passDateVisible = false;
            this.pendingOrderPage();
            this.$message.success(res.message);
          } else {
            this.$message.error(res.message);
          }
        }
      });
    },
    /**
     * 重置确认表单
     * @author wheat
     * */
    resetPassDateForm() {
      this.passForm.appointment_time = "";
    },
    /**
     * 关闭确认表单
     * @author wheat
     * */
    closePassDateForm() {
      this.passDateVisible = false;
    },
    /**
     * 打开待服务订单拒绝窗口
     * @author wheat
     * */
    openNoPass(id) {
      this.$confirm("拒绝当前订单吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      })
        .then(() => {
          this.NoPass(id);
        })
        .catch(() => {});
    },
    /**
     * 待服务订单拒绝操作
     * @author wheat
     * */
    async NoPass(id) {
      let res = await Api.NoPass({ id: id });
      if (res.code == "0") {
        this.$message.success(res.message);
        this.pendingList();
      } else {
        this.$message.error(res.message);
      }
    },
    /**
     * 切换待服务订单分页
     * @author wheat
     * */
    changePendingPage(e) {
      this.pendingPage.start = e;
      this.pendingOrderPage();
    },
    /**
     * 打开设置或修改子订单服务时间
     * @author wheat
     * */
    openChangeDate(row, type) {
      this.serviceForm.id = row.id;
      if (type == 2) {
        //设置服务时间
        this.serviceForm.appointment_time = row.appointment_start;
      } else {
        // 修改
        this.serviceForm.appointment_time = row.appointment_time;
      }
      this.serviceDateVisible = true;
    },
    /**
     * 关闭设置或修改子订单服务时间
     * @author wheat
     * */
    closeSetSubOrderServiceTime() {
      this.serviceDateVisible = false;
      this.serviceForm.appointment_time = "";
      this.serviceForm.start_time = "";
      this.serviceForm.end_time = "";
    },
    /**
     * 重置设置或修改子订单服务时间
     * @author wheat
     * */
    resetSetSubOrderServiceTime() {
      this.serviceForm.appointment_time = "";
    },

    async actionSetSubOrderServiceTime() {
      this.$refs["serviceForm"].validate(async (valid) => {
        if (valid) {
          let params = Object.assign({}, this.serviceForm);
          params["appointment_time"] = Common.getDateHS(
            params["appointment_time"]
          );
          console.log(params)
          let res = await Api.SetSubOrderServiceTime(params);
          if (res.code == "0") {
            this.serviceDateVisible = false;
            this.actionVisible = false;
            this.pendingOrderPage();
            this.$message.success(res.message);
          } else {
            this.$message.error(res.message);
          }
        }
      });
    },
    /**
     * 获取订单详情
     * @author wheat
     * */
    async getOrderDetail() {
      this.orderDetail = {};
      const res = await Api.GetOrderDetail({ id: this.orderId });

      if (res.code == "0" && res.orderRefresh == true) {
        this.actionVisible = false;
        this.pendingOrderPage();
        return;
      }
      this.detailList = res.list;
    },
    /**
     * 打开审核框
     * @author wheat
     * */
    openAction(id) {
      this.orderId = id;
      this.getOrderDetail();
      this.actionVisible = true;
    },
    /***
     * 待确认订单，拒绝服务
     * @author wheat
     */
    denialOfServiceOrder() {
      this.$confirm("此操作将改变订单状态, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const res = await Api.DenialOfServiceOrder({
            id: this.orderDetail.id,
          });
          if (res && res.code == "0") {
            this.actionVisible = false;
            this.pendingOrderPage();
            this.$message.success(res.message);
          } else {
            this.$message.error(res.message);
          }
        })
        .catch(() => {});
    },
    /**
     * 确认已收款
     * @author wheat
     * */
    async confirmReceipt(id) {
      this.$confirm("此操作将改变订单状态, 是否继续?", "确认已收款", {
        type: "info",
      })
        .then(async () => {
          let res = await Api.ConfirmThatTheConsultation({ id: id });
          if (res && res.code == 0) {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.pendingOrderPage();
          } else {
            this.$message({
              type: "info",
              message: res.message,
            });
          }
        })
        .catch(() => {});
    },
    onSearch() {
      this.pendingOrderPage();
    },
  },
};
</script>

<style scoped>
.item-block-box .item-block:last-child {
  border-bottom: none;
}

.item-block {
  font-size: 14px;
  margin-bottom: 5px;
  background-color: #ffffff;
  padding: 0 5px;
  border-radius: 4px;
  border-bottom: 1px solid #f5f5f5;
}

.ml10 {
  margin-left: 10px;
}

.list-item-box {
  text-align: left;
}

.list-item {
  border-bottom: 1px solid #f5f5f5;
  padding: 10px 0;
}

.item-success {
  color: #67c23a;
}

.item-default {
  color: #999999;
}

.item-primary {
  color: #409eff;
}
.detail-list {
  display: flex;
  border-bottom: 1px solid #eeeeee;
  padding: 8px 0;
}
.detail-list:last-child {
  border-bottom: none;
}
.detail-list .detail-left {
  width: 150px;
  text-align: right;
  padding-right: 20px;
  color: #000000;
}
.detail-list .detail-right {
  flex: 1;
  color: #333333;
}
.cu-order-dialog {
  max-height: 600px;
  overflow-y: scroll;
}
</style>
