<template>
  <div style="padding-bottom: 20px">
     <el-form :inline="true" :model="page" class="demo-form-inline">
      <el-form-item label="关键字">
        <el-input
          style="width: 250px"
          v-model="page.key"
          placeholder="订单编号、客户姓名、预约项目"
        ></el-input>
      </el-form-item>

      <el-form-item label="预约类型">
        <el-select
          v-model="page.appointment_type"
          placeholder="请选择预约类型"
        >
          <el-option label="专科医生" value="1"></el-option>
          <el-option label="健康体检" value="2"></el-option>
          <el-option label="预防疫苗" value="3"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="预约时间">
        <el-date-picker
          v-model="page.appointment_time"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="datetime"
          placeholder="选择预约时间"
        >
        </el-date-picker>
      </el-form-item>
       <el-form-item label="状态">
         <el-select
           v-model="page.state"
           placeholder="请选择状态"
         >
           <el-option label="待上传报告" value="1"></el-option>
           <el-option label="已上传报告" value="2"></el-option>
         </el-select>
       </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSearch">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" empty-text="暂无订单数据" style="width: 100%">
      <el-table-column prop="order_id" label="订单编号" width="">
        <template slot-scope="scope">
          <el-link type="primary" @click="openDetail(scope.row.id)">{{ scope.row.order_id }}</el-link>
        </template>
      </el-table-column>
      <el-table-column prop="order_over_time" width="200" label="完成时间">
      </el-table-column>
        <el-table-column prop="patient_name" width="150" label="客户姓名">
      </el-table-column>
      <el-table-column prop="mobile" width="150" label="联系电话"></el-table-column>
        <el-table-column prop="appointment_type" label="预约类型" width="150">
        <template slot-scope="scope">
          <span v-if="scope.row.appointment_type == '1'">专科医生</span>
          <span v-if="scope.row.appointment_type == '2'">健康体检</span>
          <span v-if="scope.row.appointment_type == '3'">预防疫苗</span>
        </template>
      </el-table-column>
      <el-table-column prop="product_name" width="150" label="预约项目">
      </el-table-column>
      <el-table-column prop="medical_price_hb" width="" label="金额">
      </el-table-column>
      <el-table-column
        prop="medical_fee_payment_status"
        label="支付状态"
        width="150"
      >
        <template slot-scope="scope">
          <el-tag
            type="danger"
            v-if="scope.row.medical_fee_payment_status == '1'"
            >未支付</el-tag
          >
          <el-tag
            type="success"
            v-if="scope.row.medical_fee_payment_status == '2'"
            >已支付</el-tag
          >
        </template>
      </el-table-column>
       <!-- <el-table-column
        prop="service_item_list"
        label="服务项确认状态"
        width="380"
      >
        <template slot-scope="scope">
          <div
            class="item-block-box"
            v-if="
              scope.row.service_item_list && scope.row.service_item_list.length
            "
          >
            <div
              v-for="(item, index) in scope.row.service_item_list"
              :key="index"
              class="item-block"
            >
              <div>{{ item.title }}</div>
              <div v-if="item.clinic_over_time != null">
                完成服务时间: {{ item.clinic_over_time }}
              </div>
              <div v-else-if="item.appointment_time != null">
                服务时间: {{ item.appointment_time }}
              </div>
              <div v-else>
                <span v-if="item.appointment_start != null"
                  >用户预约时间: {{ item.appointment_start }} -
                  {{ item.appointment_end }}</span
                >
                <span v-if="item.appointment_start != null"
                  >用户未提交预约时间</span
                >
              </div>
            </div>
          </div>
        </template>
      </el-table-column> -->
      <el-table-column label="操作" width="260">
        <template slot-scope="scope">
<!--          <el-button-->
<!--            v-if="scope.row.medical_fee_payment_status == 1 && scope.row.customer_arrival_time != null && scope.row.customer_arrival_time != ''"-->
<!--            type="primary"-->
<!--            plain-->
<!--            @click="openConfirmReceipt(scope.row)"-->
<!--            >确认收款</el-button-->
<!--          >-->
          <el-button
            type="primary"
            plain
            @click="openDetail(scope.row.id)"
            :loading="$store.state.requestLoading"
          >预约详情</el-button>
          <el-button
            v-if="scope.row.medical_fee_payment_status == 2"
            type="primary"
            plain
            @click="openCheckComplete(scope.row)"
            >上传病例</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div
      style="
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-top: 20px;
      "
    >
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="page.limit"
        @current-change="changePage"
        :current-page="page.start"
        :total="page.totalRow"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="确认收款"
      :visible="confirmVisible"
      width="400px"
      :destroy-on-close="true"
      :before-close="() => (confirmVisible = false)"
    >
      <el-form>
        <el-form-item style="text-align: left" label="订单金额(HK$):">{{
          confirmForm.medical_price_hb
        }}</el-form-item>
        <el-form-item style="text-align: left" label="优惠码优惠金额(HK$):">{{
          confirmForm.promo_code_price
        }}</el-form-item>
        <el-form-item style="text-align: left" label="优惠券优惠金额(HK$):">{{
          confirmForm.coupon_price
        }}</el-form-item>
        <el-form-item>
          <el-button
            @click="confirmedPaid"
            :loading="$store.state.requestLoading"
            >确认收款</el-button
          >
          <el-button
            @click="
              () => {
                confirmVisible = false;
              }
            "
            >取消</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      title="核对完成服务"
      :visible="checkVisible"
      width="800px"
      :destroy-on-close="true"
      :before-close="() => (checkVisible = false)"
    >
      <div
        class="list-item-box"
        style="text-align: left"
        v-for="(item, index) in orderDetail.service_item_list"
        :key="index"
      >
        <div class="list-item" v-if="item.clinic_over_time != null">
          <span>{{ item.title }}</span
          ><span class="ml10 item-success"
            >已完成服务，完成时间: {{ item.clinic_over_time }}</span
          >
        </div>
        <div v-else>
          <div class="list-item" v-if="item.appointment_time != null">
            <span>{{ item.title }}</span
            ><span class="ml10 item-primary"
              >待服务，预约服务时间: {{ item.appointment_time }}</span
            >
          </div>
          <div
            class="list-item"
            v-else-if="
              item.appointment_time == null && item.appointment_start != null
            "
          >
            <span>{{ item.title }}</span
            ><span class="ml10 item-primary"
              >用户预约时间: {{ item.appointment_start }} -
              {{ item.appointment_end }}</span
            >
          </div>
          <div class="list-item" v-else-if="item.appointment_start == null">
            <span>{{ item.title }}</span
            ><span class="ml10 item-default">用户未提交预约时间</span>
          </div>
          <div
            class="list-item"
            v-if="item.service_state == '3' && item.clinic_service_state == '2'"
          >
            <el-button
              type="primary"
              plain
              size="small"
              icon="el-icon-time"
              @click="checkFinish(item.id)"
              >核对完成</el-button
            >
          </div>
        </div>
      </div>
      <!--      <div style="margin-top: 20px" v-if="orderDetail.isDos == true"><el-button type="success" plain icon="el-icon-close" @click="checkFinish">核对完成</el-button></div>-->
    </el-dialog>
    <a-modal
      v-model="updateVisible"
      cancelText="取消"
      okText="上传"
      title="上传病例"
      @ok="updateHandle"
    >
      <a-form-model
        ref="formModel"
        :rules="formDataRules"
        :model="formModel"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
      >
        <a-form-model-item label="出具时间" prop="issuing_time">

          <el-date-picker
            v-model="formModel.issuing_time"
            type="datetime"
            format="yyyy-MM-dd HH:mm"
            value-format="yyyy-MM-dd HH:mm"
            placeholder="请选择出具时间"
          >
          </el-date-picker>
        </a-form-model-item>
        <a-form-model-item label="	出具人名称" prop="issuing_name">
          <a-input
            placeholder="请输入出具人名称"
            type="text"
            v-model="formModel.issuing_name"
          />
        </a-form-model-item>
        <a-form-model-item label="诊断意见" prop="diagnosis">
          <a-input
            placeholder="请输入诊断意见"
            type="textarea"
            row="4"
            v-model="formModel.diagnosis"
          />
        </a-form-model-item>
        <a-form-model-item label="诊断报告" prop="report_urls">
          <update-multiple
            :img_url.sync="report_urls"
            :type="'multiple'"
            @changeCustomFile="changeImgUrl"
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <el-dialog
      title="订单详情"
      :visible="detailsVisible"
      width="600px"
      :destroy-on-close="true"
      :before-close="() => (detailsVisible = false)"
    >
      <div class="cu-order-dialog">
        <el-card class="box-card" style="margin-bottom: 20px">
          <div slot="header" style="text-align: center;font-weight: bold;font-size: 16px">
            <span>客户详情</span>
          </div>
          <div>
            <div class="detail-list">
              <div class="detail-left">客户姓名:</div>
              <div class="detail-right">{{orderDetailInfo.name}}</div>
            </div>
            <div class="detail-list">
              <div class="detail-left">证件类型:</div>
              <div class="detail-right">
                <span v-if="orderDetailInfo.card_type == '1'">中国身份证</span>
                <span v-if="orderDetailInfo.card_type == '2'">香港身份证</span>
                <span v-if="orderDetailInfo.card_type == '3'">其它</span>
              </div>
            </div>
            <div class="detail-list">
              <div class="detail-left">证件号码:</div>
              <div class="detail-right">{{orderDetailInfo.card_num}}</div>
            </div>
            <div class="detail-list">
              <div class="detail-left">联系电话:</div>
              <div class="detail-right">{{orderDetailInfo.mobile}}</div>
            </div>
            <div class="detail-list">
              <div class="detail-left">预约类型:</div>
              <div class="detail-right">
                <span v-if="orderDetailInfo.appointment_type == '1'">专科医生</span>
                <span v-if="orderDetailInfo.appointment_type == '2'">健康体检</span>
                <span v-if="orderDetailInfo.appointment_type == '3'">预防疫苗</span>
              </div>
            </div>
            <div class="detail-list">
              <div class="detail-left">预约项目:</div>
              <div class="detail-right">{{orderDetailInfo.title}}</div>
            </div>
            <div class="detail-list">
              <div class="detail-left">订单创建时间:</div>
              <div class="detail-right">{{orderDetailInfo.create_time}}</div>
            </div>
            <div class="detail-list">
              <div class="detail-left">就诊费优惠金额:</div>
              <div class="detail-right">HK${{orderDetailInfo.coupon_price}}</div>
            </div>
            <div class="detail-list">
              <div class="detail-left">应收就诊费:</div>
              <div class="detail-right">HK${{orderDetailInfo.medical_price_hb}}</div>
            </div>
            <div class="detail-list">
              <div class="detail-left">症状描述:</div>
              <div class="detail-right">
                {{ orderDetailInfo.content }}
              </div>
            </div>
            <div class="detail-list">
              <div class="detail-left">附件:</div>
              <div class="detail-right"><small-image-list v-bind:img_url.sync="orderDetailInfo.annex_urls" /></div>
            </div>
          </div>
        </el-card>
        <el-card class="box-card" style="margin-bottom: 20px" v-if="orderDetailInfo.appointment_type != 1">
          <div slot="header" style="text-align: center;font-weight: bold;font-size: 16px">
            <span>项目详情</span>
          </div>
          <div v-for="(item, index) in orderDetailInfo.projectList" :key="index" style="margin-bottom: 20px">
            <div style="text-align: center"><span style="background: #eeeeee;padding:4px 10px;border-radius: 10px">{{item.title}}</span></div>
            <div v-for="(item1, index1) in item.item" :key="index1">
              <div style="color: #333333;margin-bottom: 5px">{{item1.name}}</div>
              <div style="color: #666666;margin-bottom: 20px">{{item1.content}}</div>
              <div style="margin-top: 20px" v-if="orderDetailInfo.appointment_type != 2">
                <div style="display: flex;border-bottom: 1px solid #eee;border-top: 1px solid #eee;line-height: 30px">
                  <div style="flex: 1;text-align: center">安排</div><div style="flex: 1;text-align: center">时间</div>
                </div>
                <div style="display: flex;border-bottom: 1px solid #eee;line-height: 30px" v-for="(item2, index2) in item1.implementation_plan" :key="index2">
                  <div style="flex: 1;text-align: center">第{{item2.num}}针</div><div style="flex: 1;text-align: center">{{item2.maa_date}}</div>
                </div>
              </div>
            </div>
          </div>
        </el-card>
        <el-card class="box-card">
          <div slot="header" style="text-align: center;font-weight: bold;font-size: 16px">
            <span>服务记录</span>
          </div>
          <el-table :data="orderDetailInfo.serviceRecordList">
            <el-table-column prop="content" label="服务内容"></el-table-column>
            <el-table-column prop="create_time" width="" label="创建时间" width="200"></el-table-column>
          </el-table>
        </el-card>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as Api from "./api";
import * as Common from "../../../common/common";
export default {
  name: "order",
  props: {
    activeIndex: [String],
  },
  watch: {
    activeIndex: {
      handler: function (e) {
        if (e == 3) {
          this.completedOrderPage();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      page: {
        start: 1,
        limit: 20,
        pageSize: 0,
        totalRow: 0,
        key: "",
        type: "",
        appointment_time: "",
        state: "",
      },
      list: [],
      confirmVisible: false,
      confirmForm: {
        id: "",
        medical_price_hb: "",
        promo_code_price: "",
        coupon_price: "",
      },
      checkVisible: false,
      checkForm: {
        id: "",
      },
      orderDetail: {},
      updateVisible: false,
      formDataRules: {
        issuing_time: [
          { required: true, message: "请选择出具时间", trigger: "change" },
        ],
        issuing_name: [
          { required: true, message: "请输入出具人名称", trigger: "change" },
        ],
        diagnosis: [
          { required: true, message: "请输入诊断意见", trigger: "change" },
        ],
      },
      report_urls: [],
      formModel: {
        id: "",
        issuing_time: "",
        issuing_name: "",
        diagnosis: "",
        report_urls: [],
      },
      orderId: "",
      actionVisible: false,
      detailList: [],
      detailsVisible: false,
      detailId: '',
      orderDetailInfo: {}
    };
  },
  mounted() {
    // this.completedOrderPage();
  },
  methods: {
    /**
     * 打开订单详情
     * @author wheat
     * */
    openDetail(id) {
      this.detailId = id;
      this.detailsVisible = true;
      this.getOrderDetailInfo();
    },
    /**
     * 订单详情
     * @author wheat
     * */
    async getOrderDetailInfo() {
      const res = await Api.GetOrderDetailInfo({id: this.detailId});
      if (res.target.appointment_type != 1) {
        res.target.projectList.forEach(project=>{
          project.item.forEach(item=>{
            try {
              item.implementation_plan = JSON.parse(item.implementation_plan)
            }catch(e) {
              item.implementation_plan = []
            }
          })
        })
      }
      this.orderDetailInfo = res.target;
    },
    /**
     * 订单列表
     * @author wheat
     * */
    async completedOrderPage() {
      let res = await Api.CompletedOrderPage(this.page);
      if (res.code == "0") {
        this.page.pageSize = res["page"]["pageSize"];
        this.page.totalRow = res["page"]["totalRow"];
        this.list = res["page"]["list"];
      } else {
        this.$message.error(res.message);
      }
    },
    /**
     * 确认收款的状态操作
     * @author wheat
     * */
    openConfirmReceipt(record) {
      this.confirmForm.id = record.id;
      if (record.appointment_type == 2 || record.appointment_type == 3) {
        this.confirmForm.medical_price_hb = record.medical_price_hb;
        this.confirmForm.promo_code_price = record.promo_code_price;
        this.confirmForm.coupon_price = record.coupon_price;
        this.confirmVisible = true;
      } else if (record.appointment_type == 1) {
        this.$confirm("确认将该订单设置为已收款吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            this.confirmedPaid();
          })
          .catch(() => {});
      }
    },
    /**
     * 确认收款
     * @author wheat
     * */
    async confirmedPaid() {
      let res = await Api.ConfirmedPaid({ id: this.confirmForm.id });
      if (res && res.code === "0") {
        this.$message.success(res.message);
        this.confirmVisible = false;
        this.pendingServiceOrder();
      } else {
        this.$message.error(res.message);
      }
    },
    /**
     * 切换订单分页
     * @author wheat
     * */
    changePage(e) {
      this.page.start = e;
      this.pendingServiceOrder();
    },
    /***
     * 订单详情
     * @author wheat
     */
    async getOrderDetail() {
      this.orderDetail = {};
      const res = await Api.GetOrderDetail({ id: this.checkForm.id });
      res.target["isDos"] = false;
      if (
        res.target.service_state == "3" &&
        res.target.clinic_service_state == "2"
      ) {
        res.target["isDos"] = true;
      }

      this.orderDetail = res.target;
    },
    /**
     * 打开核对完成服务框
     * @author wheat
     * */
    openCheckComplete(record) {
      this.updateVisible = true;
      this.formModel.id = record.id;
    },
    /**
     * 核对完成
     * @author wheat
     * */
    async checkFinish(id) {
      let res = await Api.CheckCompletionService({ id: id });
      if (res && res.code == 0) {
        this.$message({
          type: "success",
          message: res.message,
        });
        this.getOrderDetail();
        this.pendingServiceOrder();
      } else {
        this.$message({
          type: "info",
          message: res.message,
        });
      }
    },
    changeImgUrl(urls) {
      this.formModel.report_urls = urls;
      console.log(this.formModel.report_urls);
    },
    // 上传病例
    updateHandle() {
      console.log(this.formModel);
      this.$refs.formModel.validate(async (valid) => {
        if (valid) {
          let res = await Api.updateFile({ ...this.formModel });
          if (res && res["code"] == "0") {
            this.$message.success(res.message);
            this.updateVisible = false;
            this.completedOrderPage();
          } else {
            this.$message.error(res.message);
          }
        }
      });
    },
    onSearch(){
      this.completedOrderPage();
    }
  },
};
</script>

<style scoped>
.item-block-box .item-block:last-child {
  border-bottom: none;
}
.item-block {
  font-size: 14px;
  margin-bottom: 5px;
  background-color: #ffffff;
  padding: 0 5px;
  border-radius: 4px;
  border-bottom: 1px solid #f5f5f5;
}
.ml10 {
  margin-left: 10px;
}
.list-item-box {
  text-align: left;
}
.list-item {
  border-bottom: 1px solid #f5f5f5;
  padding: 10px 0;
}
.item-success {
  color: #67c23a;
}
.item-default {
  color: #999999;
}
.item-primary {
  color: #409eff;
}
.detail-list {
  display: flex;
  border-bottom: 1px solid #eeeeee;
  padding: 8px 0;
}
.detail-list:last-child {
  border-bottom:none;
}
.detail-list .detail-left {
  width: 150px;
  text-align: right;
  padding-right: 20px;
  color: #000000;
}
.detail-list .detail-right {
  flex: 1;
  color: #333333;
}
.cu-order-dialog {
  max-height: 600px;
  overflow-y: scroll;
}
</style>
