<template>
  <div class="bottom_div">
    <div class="txt_news"><span>订单管理</span></div>
    <div class="txt_bottom">
      <!-- <el-tabs v-model="activeName" type="card" @tab-click="tabClick">
        <el-tab-pane label="待确认订单" name="confirm">
          <confirmOrder :activeIndex.sync="activeIndex"/>
        </el-tab-pane>
        <el-tab-pane label="待服务订单" name="service">
          <serviceOrder :activeIndex.sync="activeIndex"/>
        </el-tab-pane>
        <el-tab-pane label="已完成订单" name="finish">
          <completeOrder :activeIndex.sync="activeIndex"/>
        </el-tab-pane>
      </el-tabs> -->
      <a-tabs type="card" @change="tabClick">
        <a-tab-pane key="1" tab="待确认订单">
          <confirmOrder v-bind:activeIndex.sync="activeIndex" />
        </a-tab-pane>
        <a-tab-pane key="2" tab="待服务订单">
          <serviceOrder v-bind:activeIndex.sync="activeIndex" />
        </a-tab-pane>
        <a-tab-pane key="3" tab="已完成订单">
          <completeOrder v-bind:activeIndex.sync="activeIndex" />
        </a-tab-pane>
      </a-tabs>
    </div>
    <!-- <div class="txt_txt">
      <div class="txt_center">
        Copyright © 2020 亿立医疗顾问（深圳）有限公司 技术支持：中企高呈
      </div>
      <div class="txt_right">
        <div class="txt_right_txt"><a href="">联系客服</a></div>
        <div class="txt_right_txt"><a href="">帮助</a></div>
        <div class="txt_right_txt"><a href="">隐私</a></div>
        <div class="txt_right_txt"><a href="">条款</a></div>
      </div>
    </div> -->
  </div>
</template>

<script>
import confirmOrder from "./confirm-order/index";
import serviceOrder from "./service-order/index";
import completeOrder from "./complete-order/index";
export default {
  name: "order",
  components: {
    confirmOrder,
    serviceOrder,
    completeOrder,
  },
  data() {
    return {
      activeName: "confirm",
      activeIndex: "",
      rowId: "",
    };
  },
  mounted() {},
  created() {
    this.rowId = this.$route.params.id;
  },
  methods: {
    tabClick(e) {
      this.activeIndex = e;
    },
  },
};
</script>

<style scoped>
</style>
