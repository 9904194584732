import request from '@/common/request';
/**
 * 已完成订单,列表
 */
export function CompletedOrderPage(data) {
  return request({
    url: '/clinic/order/completedOrderPage',
    method: 'GET',
    params: data
  });
}

/***
 * 待服务订单，核对完成
 */
export function CheckCompletionService(data) {
  return request({
    url: '/clinic/order/checkCompletionService',
    method: 'POST',
    data
  });
}

/***
 * 上传病例
 */
 export function updateFile(data) {
  return request({
    url: '/clinic/order/medicalCaseOfIllness/save',
    method: 'POST',
    data
  });
}
/**
 * 订单详情
 **/
export function GetOrderDetailInfo(data) {
  return request({
    url: '/clinic/order/detail',
    method: 'GET',
    params: data
  });
}

