import request from '@/common/request';
/**
 * 待确认订单，列表
 */
export function PendingOrderPage(data) {
  return request({
    url: '/clinic/order/pendingOrderpage',
    method: 'GET',
    params: data
  });
}

/**
 * 待确认订单，设置或修改子订单服务时间
 */
export function SetSubOrderServiceTime(data) {
  return request({
    url: '/clinic/order/setSubOrderServiceTime',
    method: 'POST',
    data
  });
}

/***
 * 待确认订单，详情
 */
export function GetOrderDetail(data) {
  return request({
    url: '/clinic/order/pendingOrderpageDetail',
    method: 'GET',
    params: data
  });
}

/**
 * 待确认订单，拒绝服务订单
 */
export function DenialOfServiceOrder(data) {
  return request({
    url: '/clinic/order/denialOfServiceOrder',
    method: 'POST',
    data
  });
}

/**
 * 订单详情
 **/
export function GetOrderDetailInfo(data) {
  return request({
    url: '/clinic/order/detail',
    method: 'GET',
    params: data
  });
}




